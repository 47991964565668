<template>
    <div>
        <CCard class="zw-page">
            <CCardBody>
                <zw-table ref="table"
                          tableName="export_history"
                          columnsPrefix="export.history.column."
                          :items="myProvider"
                          :fields="fields"
                          :filter="filter"
                          :selected="selected"
                          :selectedSource="selectedSource"
                          title="export.history.title.export_history"
                >
                    <template #cell(user)="row">
                        {{ row.item.user.email }}
                    </template>
                    <template #cell(filename)="row">
                        <div v-if="row.item.filename"><a @click.stop="$root.downloadDocument(row.item.filename, 'export')" href="#">{{ row.item.filename }}</a></div>
                        <div v-else>-</div>
                    </template>
                    <template #cell(status)="row">
                        <CBadge :color="statusClass(row.item.status)">{{ row.item.status }}</CBadge>
                    </template>
                    <template #cell(actions)="row">
                        <b-dropdown class="mb-1"
                                    left
                                    variant="primary"
                                    size="sm"
                        >
                            <template #button-content>
                                {{ $t('common.label.actions') }}
                            </template>

                            <b-dropdown-item @click="deleteRow(row.item.id)">
                                <font-awesome-icon class="mr-2" icon="trash"/>
                                {{ $t('common.title.delete') }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </template>
                </zw-table>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'

export default {
    name: 'ExportHistory',
    mixins: [commonTable],
    data() {
        return {
            saveFilters: false,
        }
    },
    methods: {
        ...mapGetters('Export', ['getExportHistory']),
        myProvider(ctx) {
            return this.commonProviderFetch(ctx, 'Export/fetchExport', 'getExportHistory');
        },
        getActions() {
        },
        deleteRow(id) {
            this.$removeConfirm('Export/deleteExportHistory', id, this.refreshTable)
        },
        statusClass(status) {
            if (status == 'handled') {
                return 'light';
            } else if (status == 'progress') {
                return 'warning'
            } else {
                return 'success';
            }
        },
    },
}
</script>